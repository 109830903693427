<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
// import moment from 'moment';
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Departemen",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Departemen",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Departemen",
          active: true,
        },
      ],
      // variable Page Table
      loadingTable: true,
      table_data: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",
      options_status: ["ENABLE", "DISABLE"],
      selected: [],
      status: "ENABLE",
      search: "",
    };
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    getDataTable() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/departemen?status=" +
            self.status +
            "&search=" +
            self.search
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.table_data = response_data.list_data.data;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    //filter data
    filterData() {
      this.getDataTable();
    },
    eksporHandle() {
      const link = document.createElement("a");
      link.href = process.env.VUE_APP_BACKEND_URL+"/fitur/ekspor?x=m_departemen&status="+this.status;
      link.click();
    },
    changeStatusData(status, id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin mengubah status data?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("status", status);
          data.append("id", id);
          var config = {
            method: "POST",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/master/departemen/ubahstatus",
            data: data,
          };
          axios(config).then((response) => {
            console.log(response);
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Status data berhasil diubah.",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              didOpen: () => {
                timerInterval = setInterval(() => {
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.getDataTable();
              }
            });
          });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <br />
                <div class="row">
                  <div class="col-md-12">
                    <div class="filter">
                      <div class="row" style="margin-bottom: 10px">
                        <div class="col-md-2">
                          <small for="">Status</small>
                          <select
                            class="form-control input-sm"
                            v-model="status"
                          >
                            <option value="ENABLE">ENABLE</option>
                            <option value="DISABLE">DISABLE</option>
                          </select>
                        </div>
                        <div class="col-md-2">
                          <button
                            class="btn btn-primary"
                            @click="filterData"
                            type="button"
                            style="margin-top: 25px; border-radius: 0px"
                          >
                            <i class="fa fa-filter"></i> Filter
                          </button>
                        </div>
                      </div>
                    </div>
                    &nbsp;
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-9"></div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control"
                      id="searchTable"
                      v-model="search"
                      @input="getDataTable()"
                      placeholder="Cari Data ..."
                    />
                  </div>
                </div>
                <br />
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                    <thead
                      class="text-center text-white"
                      style="background-color: #132d4a"
                    >
                      <tr>
                        <th style="width: 50px">No</th>
                        <th style="width: 150px">Code</th>
                        <th style="width: 150px">Nama</th>
                        <th style="width: 150px">Status</th>
                        <th style="width: 175px">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="4">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else-if="table_data == ''">
                        <td class="text-center" colspan="6">
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(row_data, key_data) in table_data"
                        :key="key_data"
                      >
                        <td class="text-center">
                          {{ key_data + 1 }}
                        </td>
                        <td class="text-center">
                          {{ row_data.code }}
                        </td>
                        <td class="text-center">
                          {{ row_data.nama }}
                        </td>
                        <td class="text-center">
                        <div v-if="row_data.status == 'ENABLE'">
                          <button
                            type="button"
                            class="btn btn-success btn-sm"
                            v-on:click="
                              changeStatusData('DISABLE', row_data.id)
                            "
                          >
                            <i class="bx bx-check-double"></i> ENABLE
                          </button>
                        </div>
                        <div v-else>
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            v-on:click="changeStatusData('ENABLE', row_data.id)"
                          >
                            <i class="fas fa-undo"></i> DISABLE
                          </button>
                        </div>
                      </td>
                        <td class="text-center">
                          <router-link
                            :to="{
                              name: 'edit-departemen',
                              params: { id: row_data.id },
                            }"
                            class="btn btn-sm btn-primary m-1"
                            ><i class="fa fa-edit"></i> Edit</router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
